// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$fuec-primary: mat.define-palette(mat.$indigo-palette);
$fuec-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$fuec-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$fuec-theme: mat.define-light-theme(
    (
      color: (
        primary: $fuec-primary,
        accent: $fuec-accent,
        warn: $fuec-warn
      )
    )
);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($fuec-theme);

/* You can add global styles to this file, and also import other style files */
// @import '~bootstrap/scss/bootstrap';

//@import url('https://code.ionicframework.com/ionicons/2.0.1/css/ionicons.min.css');
//@import url('https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.3.0/css/flag-icon.min.css');
//@import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro:300,400,400i,700');

//@import '~admin-lte/plugins/fontawesome-free/css/all.min.css';
//@import '~admin-lte/plugins/icheck-bootstrap/icheck-bootstrap.min.css';
//@import '~admin-lte/dist/css/adminlte.min.css';

$breakpoint-xs: 575px;
$breakpoint-sm: 767px;
$breakpoint-md: 991px;
$breakpoint-lg: 1191px;
$breakpoint-xl: 1399px;

@import 'ngx-toastr/toastr';
@import 'motion-styles/scss/motion-stylesheet.scss';

body {
  width: 100vw;
  height: 100vh;
}

.no-scroll {
  overflow: hidden;
}

::-webkit-scrollbar {
  width: 0px;
  background: transparent;
}

/// 2021 FUEC Styles ///

// @import "../../motion-styles-2021/scss/motion-stylesheet.scss";
// @import "../../../../styiles/motion-styles-2021/scss/motion-stylesheet.scss";
// @import "../../../motion-styles-2021/scss/motion-stylesheet.scss";
@import 'motion-styles/scss/motion-stylesheet.scss';

/// GENERAL STYLES ///

h2.s-subtitle {
  color: $red1;
  font-weight: 600;
}

input {
  font-size: smaller !important;
  margin-top: 0px !important;
  padding: 0.5em 1.75em !important;
  // color: #c5c5c5 !important;
  margin-top: 10px !important;
}

select {
  font-size: smaller !important;
  margin-top: 0px !important;
  height: 5vh !important;
  padding: 0.5em 1.75em !important;
  color: #c5c5c5 !important;
  margin-top: 10px !important;
}

.fuec-form,
.crear-fuec-form {
  align-items: start;
  margin: 6vh 0;
  column-gap: 4vw;
}

.fuec-form {
  grid-template-columns: 14vw auto auto auto;
}

.content-wrapper {
  background-color: #ffffff !important;
  padding-top: 2rem;
  padding-left: 8rem;
  padding-right: 8rem;
}

.label-title {
  font-size: 1rem !important;
  color: $gray1 !important;
  font-weight: 400 !important;
}

.logo-firma-container {
  text-align: center;
  row-gap: 3vh;

  .input-archivo {
    img {
      width: 14vw;
      margin-bottom: 1rem;
    }
  }
}

.inputs-container {
  justify-items: start;
  row-gap: 3vh;
  align-items: end;
  column-gap: 8vw;

  .input-div,
  .add-input {
    width: 100%;
    column-gap: 1vw;
    grid-template-columns: auto 104px;
    justify-items: left;
    align-items: end;
  }

  .input-radio-div {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: end;
  }

  .pt-4 {
    margin-top: 10px;
  }

  .input-radio {
    height: auto;
  }
}

.firma-div {
  width: 100%;
  justify-content: center;

  button[type='submit'] {
    width: 100%;
  }
}

.historial-container {
  margin-top: 4vh;
  margin-bottom: 0;
  row-gap: 2vh;

  table {
    width: 100%;
    margin-top: 1vh;
    margin-bottom: 2vh;

    th,
    td {
      font-size: 0.8rem;
      min-width: 4vw;
      max-width: 10vw;

      &.sort-down,
      &.sort-up {
        padding-right: 0.5em;
      }
    }

    td {
      padding-top: 1em;
      padding-bottom: 1em;
    }
  }
}

.table-options {
  input {
    margin: 0;
  }
}

.table-pdf-icon {
  width: 1.5em;
}

tr:hover,
tr.hover {
  background-color: $red1;

  .table-pdf-icon {
    color: white;
    fill: white;
  }
}

th {
  color: #fff4de;
}

/// FOOTER STYLES ///
.footer-div {
  width: 100%;
  height: fit-content;
  margin: 2vh 0;

  p {
    margin: 0;
  }
}

.footer-div:first-child {
  text-align: left;
}

.footer-div:last-child {
  text-align: right;
}

.toast-success {
  background-color: $red1;
  border-radius: 2em !important;

  .toast-message {
    color: #fff;
    font-family: 'Montserrat';
  }
}

.toast-error {
  background-color: $red1;
  border-radius: 2em !important;

  .toast-message {
    color: #fff;
    font-family: 'Montserrat';
  }
}


@media screen and (max-width: $breakpoint-xs) {
  .content-wrapper {
    padding: 2rem;
  }
}

@media (min-width: ($breakpoint-xs + 1)) and (max-width: $breakpoint-sm) {
  .content-wrapper {
    padding: 1rem 5rem;
  }

  .logo-firma-container {
    padding-bottom: 2rem;
  }

  .inputs-container {
    width: calc(100vw - 10rem) !important;
  }
}

@media (min-width: ($breakpoint-sm + 1)) and (max-width: $breakpoint-md) {
  .content-wrapper {
    padding: 1rem 4rem;
  }
}
